<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div v-bind:title="'Selectable rows '">
          <template>
            <v-card>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                      color="primary"
                      dark
                      class="ml-auto ma-3 mr-1"
                      @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <!--                    <v-btn v-if="hasPermission('permissions.create')" color="primary" dark class="ma-3 ml-0" v-on="on">-->
                    <!--                      Add-->
                    <!--                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>-->
                    <!--                    </v-btn>-->
                  </div>
                </template>
                <v-card>
                  <i
                    @click="closeMainDialog"
                    class="fas fa-times custom-times"
                  ></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit {{ editedItem.name }}</span>
                    <span v-else>Create Permission</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form
                      ref="form"
                      v-model="validationRules.valid"
                      lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.name"
                            :counter="30"
                            :rules="validationRules.nameRules"
                            label="Name*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.display_name"
                            :counter="30"
                            :rules="validationRules.displayNameRules"
                            label="Display name*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="editedItem.description"
                            :counter="100"
                            :rules="validationRules.descriptionRules"
                            label="Description*"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showEditDialog()"
                      >Cancel</v-btn
                    >
                    <v-btn
                      :disabled="!validationRules.valid"
                      color="blue darken-1"
                      text
                      @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div
                      v-if="errors != undefined && errors.length >= 0"
                      role="alert"
                      v-bind:class="{ show: errors.length }"
                      class="alert fade alert-danger"
                    >
                      <div
                        class="alert-text"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0" />
              <v-skeleton-loader
                  :loading="loading"
                  v-if="isLoaded"
                  type="table"
              >
              </v-skeleton-loader>
              <v-card-title>
                Permissions ({{ totalRecords }})
                <v-spacer></v-spacer>
                <v-text-field
                  @keydown.enter="searchQueryData"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalRecords"
                :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                :loading="loading"
                v-show="!isLoaded"
                mobile-breakpoint="800"
                class="elevation-0"
              >
                <!--                <template v-slot:item.actions="{ item }">-->
                <!--                  <div class="text-truncate">-->
                <!--                    <v-btn v-if="hasPermission('permissions.edit')" color="primary" @click="showEditDialog(item)" dark class="mr-2">-->
                <!--                      Edit-->
                <!--                      <v-icon  dark right small>fas fa-edit</v-icon>-->
                <!--                    </v-btn>-->
                <!--                    <v-btn v-if="hasPermission('permissions.delete')" color="red" dark @click="deleteItem(item)" class="mr-2">-->
                <!--                      Delete-->
                <!--                      <v-icon  dark right small>fas fa-trash</v-icon>-->
                <!--                    </v-btn>-->
                <!--                  </div>-->
                <!--                </template>-->
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PERMISSIONS_LIST,
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  CLEAR_PERMISSION_ERRORS,
  DELETE_PERMISSION
} from "@/core/services/store/permissions.module";
import { SET_CONFIRMATION } from "@/core/services/store/confirmation.module";
// import Confirmation from "@/view/content/Confirmation";

export default {
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
          name: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Display name",
          value: "display_name",
          name: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Description",
          value: "description",
          name: "name",
          filterable: true,
          sortable: false
        }
        // {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 0,
        totalItems: 0
      },
      totalRecords: 0,
      rowsPerPageItems: [10, 15, 20, 30, 40, 50],
      columns: [],
      rRole: "",
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "Name is required",
          v =>
            (v && v.length <= 30) ||
            "Must be less than or equal to 30 characters"
        ],
        displayNameRules: [
          v => !!v || "Display Name is required",
          v =>
            (v && v.length <= 30) ||
            "Must be less than or equal to 30 characters"
        ],
        descriptionRules: [
          v => !!v || "Description Name is required",
          v =>
            (v && v.length <= 100) ||
            "Must be less than or equal to 100 characters"
        ]
      },
      items: [],
      dialog: false,
      rDialog: false,
      editedItem: {}
    };
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PERMISSION_ERRORS);
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
        "&columns[" +
          i +
          "][data]=" +
          this.headers[i].value +
          "&columns[" +
          i +
          "][name]=" +
          this.headers[i].name +
          "&columns[" +
          i +
          "][searchable]=" +
          this.headers[i].filterable +
          "&columns[" +
          i +
          "][orderable]=" +
          this.headers[i].sortable +
          "&columns[" +
          i +
          "][search][value]=" +
          "&columns[" +
          i +
          "][search][regex]=" +
          false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Team Management", route: "permissions" },
      { title: "Permissions" }
    ]);
  },
  methods: {
    searchQueryData: function(e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    closeMainDialog() {
      this.dialog = !this.dialog;
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise(resolve => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fecth per page
        params.start =
          params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params)
          .map(key => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&");
        query +=
          "&search[value]=" +
          params.query +
          "&search[regex]=" +
          false +
          "&order[0][column]=" +
          params.sortBy +
          "&order[0][dir]=" +
          (params.descending ? "desc" : "asc");
        this.$store
          .dispatch(PERMISSIONS_LIST, { q: query, columns: this.columns })
          .then(res => {
            this.draw++;
            let items = res.records.data;
            let total = res.records.recordsFiltered;
            this.loading = !this.loading;
            this.isLoaded = !this.isLoaded;
            resolve({
              items,
              total
            });
          });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.editedItem = item || {};
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
        let method = CREATE_PERMISSION;
        let id = item.id;
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_PERMISSION;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_PERMISSION_ERRORS);
        // save the record
        this.$store.dispatch(method, { slug: id, data: data.data }).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {};
          this.dialog = !this.dialog;
        });
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.name + "'?",
        clr: "red",
        callback: function() {
          let id = item.id;
          this.$store
            .dispatch(DELETE_PERMISSION, {
              permission_id: id
            })
            .then(() => {
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.permissions.errors
    })
  }
};
</script>
